<template>
    <section class="works">
        <my-loader v-if="!isLoading" />

        <h1 class="works__title">Примеры моих работ</h1>

        <span class="works__text">Мои работы буду делиться на несколько категорий: личные коммерческие работы, работы в команде различных организаций и работы для развития навыках. Все ссылки на работы буду живые, а в работах по развитию навыках будут ссылки и на код с которым можно будет ознакомиться.</span>

        <h2 v-if="commercial.slides" class="works__subtitle">Коммерческие проекты:</h2>

        <carousel  v-if="commercial.slides" class="works__slides" :wrap-around="true" :breakpoints="breakpoints">
            <slide class="works__slide" v-for="(slide, index) in commercial.slides" :key="`commercial-slide-${index}`">
                <a class="works__slide-link" :href="slide.link" target="blank">
                    <img class="works__slide-img" :src="slide.img" alt="Изображение слайда" />

                    <h3 class="works__slide-title">{{ slide.title }}</h3>

                    <span class="works__slide-text">Стек: <span class="color-blue">{{ slide.stack }}</span></span>

                    <span class="works__slide-text" v-html="slide.description"></span>
                </a>
            </slide>

            <template #addons="{ slidesCount }">
                <navigation v-if="slidesCount > 3 && windowWidth > 767" />

                <pagination v-if="slidesCount > 3 || (slidesCount > 1 && windowWidth < 768)" />
            </template>
        </carousel>
    </section>
</template>

<script setup>
import { ref } from 'vue'
import axios from "axios";
import { useMeta } from 'vue-meta'
import { updateWidth } from "@/plugins/windowWidth.js";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

const { windowWidth } = updateWidth();
const commercial = ref({});
const development = ref({});
const isLoading = ref(true);

// https://ismail9k.github.io/vue3-carousel/

const breakpoints = ref({
    768: {
        itemsToShow: 2.25,
        snapAlign: 'start'
    },
    1024: {
        itemsToShow: 3.25,
        snapAlign: 'start'
    }
});

const fetchSlider = async () => {
    try {
        isLoading.value = false;
        const responce = await axios.get('./json/app.json');
        commercial.value = responce.data.works.commercial;
        development.value = responce.data.works.development;
    } catch (error) {
        alert('Ошибка загрузки данных моих работ')
    } finally {
        isLoading.value = true;
    }
};
fetchSlider();

useMeta({
    title: 'Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика',
    link: [{ rel: "canonical", href: `https://spartfin.ru/works` }],
    description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Примеры работ | Работы Spartfin | Сайты Spartfin | Spartfin | Алексей Горынь | Портфолио разработчика'
});
</script>

<style lang="scss">
.works {
    &__title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;

        @include mq(1023) {
            font-size: 32px;
            margin: 0 100px 28px;
        }

        @include mq(767) {
            font-size: 26px;
            line-height: 1.12;
            margin: 0 32px 20px;
        }
    }

    &__text {
        line-height: 1.5;
        margin-bottom: 24px;

        @include mq(1023) {
            line-height: 1.4;
            margin-bottom: 20px;
        }

        @include mq(767) {
            line-height: 1.3;
            margin-bottom: 16px;
        }
    }

    &__subtitle {
        margin: 16px 0 0;

        @include mq(767) {
            margin: 12px 0 8px;
        }
    }

    &__slides {
        .carousel__track {
            margin-bottom: 16px;
        }

        .carousel__slide {
            align-items: flex-start;
        }

        .carousel__prev {
            left: -25px;
            background-color: $color_purple;
            border-radius: 50%;
        }

        .carousel__next {
            right: -25px;
            background-color: $color_purple;
            border-radius: 50%;
        }

        @include mq(1023) {
            .carousel__track {
                margin-bottom: 0;
            }
        }
    }

    &__slide {
        padding: 24px 8px;

        @include mq(1023) {
            padding: 12px 8px;
        }

        @include mq(767) {
            padding: 6px;
        }
    }

    &__slide-link {
        display: flex;
        flex-direction: column;
        padding: 12px;
        height: 100%;
        background-color: #f0f0f3;
        box-shadow: 4px 4px 15px #d3d3d4, -4px -4px 15px $color_white;
        border-radius: 12px;
        transition: all 0.3s;

        &:hover {
            background: $color_light-blue;
            box-shadow:  10px 10px 21px #bec7d9, -10px -10px 21px $color_white;
            transform: scale(1.03);

            @include mq(1023) {
                box-shadow: none;
                transform: scale(1);
            }
        }

        @include mq(767) {
            margin: 0;
            box-shadow: none;
        }
    }

    &__slide-img {
        display: block;
        border-radius: 6px;
    }

    &__slide-title {
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        margin: 12px 0 0;

        @include mq(1023) {
            line-height: 1.4;
        }

        @include mq(767) {
            line-height: 1.3;
        }
    }

    &__slide-text {
        display: block;
        text-align: center;
        margin: 4px 0 0 0;

        span {
            font-weight: 700;
            color: $color_blue;
        }
    }
}
</style>
