<template>
    <button class="btn">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'my-button'
}
</script>

<style lang="scss" scoped>
.btn {
    padding: 10px 15px;
    color: $color_black;
    background: $color_white;
    border: 1px solid $box_shadow;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        opacity: 0.4;
    }
}
</style>
