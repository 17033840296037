<template>
    <div class="loader">
        <div class="loader__inner"></div>
    </div>
</template>

<script>
export default {
    name: 'my-loader'
}
</script>

<style lang="scss" scoped>
.loader {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
    height: 100%;
    width: 100%;
    background: none repeat scroll 0 0 $color_white;

    &__inner {
        position: absolute;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        height: 80px;
        width: 80px;
        clip: rect(0, 80px, 80px, 40px);
        animation: animate 1.5s linear infinite;

        &::after {
            position: absolute;
            content:'';
            height: 80px;
            width: 80px;
            border-radius: 50%;
            clip: rect(0, 80px, 80px, 40px);
            animation: animate2 1.5s ease-in-out infinite;
        }
    }

    @keyframes animate {
        0% {
            transform: rotate(0deg)
        }
        100% {
            transform: rotate(220deg)
        }
    }

    @keyframes animate2 {
        0% {
            box-shadow: inset $color_blue 0 0 0 17px;
            transform: rotate(-140deg);
        }
        50% {
            box-shadow: inset $color_blue 0 0 0 2px;
        }
        100% {
            box-shadow: inset $color_blue 0 0 0 17px;
            transform: rotate(140deg);
        }
    }
}
</style>
