<template>
	<section class="blog">
		<my-loader v-if="!isLoading" />

		<h1 class="blog__title">Страница в разработке</h1>

		<span class="blog__text"></span>

		<carousel v-if="main.slides && !isSliderLoading" class="blog__slides" :settings="settingsCarousel">
			<slide v-for="(slide, index) in main.slides" :key="`slide-${index}`">
				<img class="blog__slide" :src="slide.img" alt="Изображение слайда" />
			</slide>

			<template #addons>
				<navigation v-if="windowWidth > 767" />

				<pagination />
			</template>
		</carousel>

		<a class="blog__spartfin" href="https://www.stratege.ru/users/spartfin" target="blank">
			<img src="https://cards.stratege.ru/3/spartfin.png" alt="Карточка PSN Spartfin">
		</a>

		<!--<my-button @click="getData">GetPsnSpafinData</my-button>-->
	</section>
</template>

<script setup>
import { ref } from 'vue';
import axios from "axios";
import { useMeta } from 'vue-meta';
import { updateWidth } from "@/plugins/windowWidth.js";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import MyButton from '../components/UI/MyButton.vue';
import {
	exchangeRefreshTokenForAuthTokens,
	exchangeCodeForAccessToken,
	exchangeNpssoForCode,
	getTitleTrophies,
	getUserTitles,
	getUserTrophiesEarnedForTitle,
	makeUniversalSearch,
	TrophyRarity
} from "psn-api";

const main = ref({});
const isSliderLoading = ref(false);
const { windowWidth } = updateWidth();
const isLoading = ref(true);

// https://ismail9k.github.io/vue3-carousel/
const settingsCarousel = ref({
	wrapAround: true,

	breakpoints: {
		1024: {
			itemsToShow: 1,
		}
	}
});

const fetchSlider = async () => {
	try {
		isLoading.value = false;
		const responce = await axios.get('./json/app.json');
		main.value = responce.data.main;
	} catch (error) {
		alert('Ошибка загрузки данных слайдера')
	} finally {
		isLoading.value = true;
	}
};
fetchSlider();

/* const getData = async () => {
	try {
		const responce = await axios.get('https://m.np.playstation.com/api/trophy/v1/users/spartfin/trophyTitles');
		console.log(responce.data, 'psn');
	} catch (error) {
		console.log(error, 'error')
	} finally {
	}
}; */

/* const getData = async() => {
	const myNpsso = "HUAq323oHvaiA4SsDHleSGlgN3kxkLQ8I1SbalfWRAtdvushjNVE9aK0cIiXtAy5";

	try{
		// We'll exchange your NPSSO for a special access code.
		const accessCode = await exchangeNpssoForCode(myNpsso);
		console.log(accessCode, 'accessCode');
		alert(accessCode);

		// 🚀 We can use the access code to get your access token and refresh token.
		const authorization = await exchangeCodeForAccessToken(accessCode);
		console.log(authorization, 'authorization');

		const trophyTitlesResponse = await getUserTitles({accessToken: authorization.accessToken },"me");
		console.log(trophyTitlesResponse, 'trophyTitlesResponse');
	} catch (error) {
		console.log(error, 'error')
	} finally {
	}
}; */

useMeta({
	title: 'Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика',
	link: [{ rel: "canonical", href: `https://spartfin.ru/blog` }],
	description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика'
});
</script>

<style lang="scss">
.blog {
	&__title {
		font-size: 36px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 32px;

		@include mq(1023) {
			font-size: 32px;
			margin: 0 100px 28px;
		}

		@include mq(767) {
			font-size: 26px;
			line-height: 1.12;
			margin: 0 32px 20px;
		}
	}

	&__text {
		line-height: 1.5;
		margin-bottom: 24px;

		@include mq(1023) {
			line-height: 1.4;
			margin-bottom: 20px;
		}

		@include mq(767) {
			line-height: 1.3;
			margin-bottom: 16px;
		}
	}

	&__slides {
		.carousel__prev {
			left: -25px;
			background-color: $color_purple;
			border-radius: 50%;
		}

		.carousel__next {
			right: -25px;
			background-color: $color_purple;
			border-radius: 50%;
		}
	}

	&__slide {
		height: 50vh;
		object-fit: cover;
	}

	&__spartfin {
		display: flex;
		width: 50%;
		transition: 0.3s;
		margin: 32px auto;

		&:hover {
			transform: scale(1.05);
		}

		@include mq(767) {
			width: 100%;
		}
	}
}
</style>

