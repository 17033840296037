<template>
    <section class="main">
        <h1 class="main__title"><span>Spartfin</span> - моё портфолио приложение разработанное на&nbsp;фреймворке <a href="https://vuejs.org" target="blank">Vue&nbsp;3</a></h1>

        <p class="main__text">
            <a href="https://ru.wikipedia.org/wiki/Прогрессивное_веб-приложение" target="blank">PWA</a> приложение расскажет <router-link to="/about">обо мне</router-link>,
            моих основных <router-link to="/skills">навыках</router-link> и изученных технологиях фронтенд разработки,
            покажет вам живые <router-link to="/works">примеры</router-link> моих работ с исходным кодом.
            В&nbsp;специальном разделе приложения вы сможете найти мои <router-link to="/contacts">контакты</router-link> для связи со мной.
        </p>

        <p class="main__text">
            В приложение есть раздел в котором я взаимодействую с различными <router-link to="/rest-api">API</router-link> взятыми в открытом доступе,
            раздел с&nbsp;личным <router-link to="/blog">блоком</router-link> в котором я буду рассказывать об нововведениях приложения, о личных успехах, мысли о разном в&nbsp;свободной тематике.
        </p>

        <p class="main__text">
            Приложение разработано с&nbsp;минимальным количеством библиотек и с&nbsp;максимально дружественной <a href="https://ru.wikipedia.org/wiki/Поисковая_оптимизация" target="blank">поисковой оптимизацией (SEO)</a>.
        </p>

        <video class="main__code" autoplay loop muted playsinline>
            <source src="/img/video/code.webm" type="video/webm" />

            <source src="/img/video/code.mp4" type="video/mp4" />
        </video>
    </section>
</template>

<script setup>
import { useMeta } from 'vue-meta'

useMeta({
    title: 'Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика',
    link: [{ rel: "canonical", href: `https://spartfin.ru` }],
    description: 'description", content: "Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика'
});
</script>

<style lang="scss">
.main {
    &__title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;

        @include mq(1023) {
            font-size: 32px;
            margin: 0 100px 28px;
        }

        @include mq(767) {
            font-size: 26px;
            line-height: 1.12;
            margin: 0 32px 20px;
        }

        span {
            font-size: 44px;
            font-weight: 700;
            color: $color_blue;

            @include mq(1023) {
                font-size: 40px;
            }

            @include mq(767) {
                font-size: 36px;
            }
        }

        a {
            font-size: 42px;
            font-weight: 700;
            color: #42b883;

            @include mq(1023) {
                font-size: 38px;
            }

            @include mq(767) {
                font-size: 32px;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__text {
        line-height: 1.5;
        margin-bottom: 24px;

        @include mq(1023) {
            line-height: 1.4;
            margin-bottom: 20px;
        }

        @include mq(767) {
            line-height: 1.3;
            margin-bottom: 16px;
        }

        a {
            font-weight: 700;
            color: $color_blue;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__code {
        width: 100%;
        border-radius: 6px;

        @include mq(1023) {
        }

        @include mq(767) {
        }
    }
}
</style>
