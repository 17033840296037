<template>
    <section class="contacts">
        <my-loader v-if="!isLoading" />

        <h1 class="contacts__title">Способы связи&nbsp;для сотрудничества</h1>

        <p class="contacts__text">Для ваших предложений и различных вопросов по сотрудничеству вы можете связаться со мной любым ниже представленным способом:</p>

        <ul v-if="contacts.socials" class="contacts__list">
            <li class="contacts__item" v-for="(social, index) in contacts.socials" :key="`social-${index}`">
                <a class="contacts__link" :href="social.link" target="blank">
                    <img class="contacts__icon" :src="social.img.src" :alt="social.img.alt" />

                    <span class="contacts__text" v-html="social.text"></span>
                </a>
            </li>
        </ul>
    </section>
</template>

<script setup>
import { ref } from 'vue'
import axios from "axios";
import { useMeta } from 'vue-meta'

const contacts = ref({});
const isLoading = ref(true);

const fetchContacts = async () => {
    try {
        isLoading.value = false;
        const responce = await axios.get('./json/app.json');
        contacts.value = responce.data.contacts;
    } catch (error) {
        alert('Ошибка загрузки данных страницы контакты')
    } finally {
        isLoading.value = true;
    }
};
fetchContacts();

useMeta({
    title: 'Разработка приложений на vue 3 | Связь со мной | Контакты Алексея Горынь | Связь с Алексеем Горынь | Spartfin',
    link: [{ rel: "canonical", href: `https://spartfin.ru/contacts` }],
    description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика | Контакты Алексея Горынь | Связь со мной | Связь с Алексеем Горынь'
});
</script>

<style lang="scss" scoped>
.contacts {
    &__title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;

        @include mq(1023) {
            font-size: 32px;
            margin: 0 100px 28px;
        }

        @include mq(767) {
            font-size: 26px;
            line-height: 1.12;
            margin: 0 36px 20px;
        }
    }

    &__text {
        line-height: 1.5;
        text-align: center;

        @include mq(1199) {
            font-size: 18px;
            line-height: 1.4;
        }

        @include mq(767) {
            font-size: 16px;
            line-height: 1.3;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 24px 0 0;

        @include mq(767) {
            margin: 12px 0 0;
        }
    }

    &__item {
        width: calc(25% - 24px);
        padding: 24px;
        background-color: #f0f0f3;
        box-shadow: 10px 10px 21px #d3d3d4, -10px -10px 21px $color_white;
        border-radius: 12px;
        margin: 12px;
        transition: all 0.3s;

        @include mq(1199) {
            width: calc(25% - 20px);
            padding: 16px;
            margin: 10px;
        }

        @include mq(767) {
            width: calc(50% - 12px);
            padding: 10px;
            margin: 6px;
        }

        &:hover {
            background: $color_light-blue;
            box-shadow:  10px 10px 21px #bec7d9, -10px -10px 21px $color_white;
            transform: scale(1.03);

            .contacts__text {
                color: $color_red;
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;

        img {
            display: block;
            padding: 12px;

            @include mq(767) {
                padding: 6px;
            }
        }
    }
}
</style>
