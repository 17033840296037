<template>
    <div class="app">
        <metainfo>
            <template v-slot:title="{ content }">{{
                content
                    ? `${content} | Spartfin | Алексей Горынь | Портфолио разработчика`
                    : `Spartfin | Алексей Горынь | Портфолио разработчика`
            }}</template>
        </metainfo>

        <nav-bar :navBar="data.navBar"></nav-bar>

        <div class="app__content container">
            <router-view></router-view>
        </div>

        <transition name="fade-secret">
            <div v-if="secret == 16" class="app__secret" @click="secret = 0">
                <div class="app__secret-wrap">
                    <div
                        class="app__figure app__circle app__circle--left"
                    ></div>
                    <div
                        class="app__figure app__circle app__circle--right"
                    ></div>
                    <div class="app__figure app__figure--square"></div>
                </div>
            </div>
        </transition>

        <my-button
            v-if="secret != 16"
            class="app__btn app__btn--top"
            @click="toggleTextTop"
        >
            <transition name="fade">
                <span v-if="isTextVisibleTop">{{ secret }}</span>
            </transition>
        </my-button>

        <my-button
            v-if="secret != 16"
            class="app__btn app__btn--bottom"
            @click="toggleTextBottom"
        >
            <transition name="fade">
                <span v-if="isTextVisibleBottom">{{ secret }}</span>
            </transition>
        </my-button>
    </div>
</template>

<script setup>
import navBar from "@/components/NavBar.vue";
import axios from "axios";
import { ref } from "vue";
import { useMeta } from "vue-meta";
import MyButton from "@/components/UI/MyButton.vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const data = ref({
    navBar: {},
});
const secret = ref(0);
const isTextVisibleTop = ref(false);
const isTextVisibleBottom = ref(false);

const toggleTextTop = () => {
    if (secret.value < 29) {
        secret.value = secret.value + 10;
    } else {
        secret.value = 0;
    }

    isTextVisibleTop.value = true;

    setTimeout(() => {
        isTextVisibleTop.value = false;
    }, 500);
};

const toggleTextBottom = () => {
    if (secret.value < 29) {
        secret.value = secret.value + 3;
    } else {
        secret.value = 0;
    }
    isTextVisibleBottom.value = true;

    setTimeout(() => {
        isTextVisibleBottom.value = false;
    }, 500);
};

router.beforeEach((to, from, next) => {
    secret.value = 0;
    next();
});

const fetch = async () => {
    try {
        const responce = await axios.get("./json/app.json");
        data.value = responce.data;
    } catch (error) {
        alert("Ошибка загрузки data app");
    } finally {
    }
};
fetch();
</script>

<style lang="scss" scoped>
.app {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1440px;
    min-height: 100vh;
    height: auto;
    margin: 0 auto;

    &__content {
        width: 100%;
        margin: 36px 0;

        @include mq(1023) {
            margin: 28px 0;
        }

        @include mq(767) {
            margin: 16px 0;
        }
    }

    &__secret {
        position: fixed;
        top: 20vh;
        left: calc(50% - 156.5px);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 313px;
        height: 360px;
        background-image: url("/img/secret/love-me.png");
        background-size: 100% 100%;
    }

    &__secret-wrap {
        position: relative;
        top: 75%;
        left: 0%;
        width: 100px;
        height: 100px;
        animation-name: pulse;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        animation-direction: alternate;
    }

    &__figure {
        position: absolute;
        width: 100px;
        height: 100px;
        background: $color_red;

        &--square {
            transform: rotate(45deg);
            bottom: -25px;
        }
    }

    &__circle {
        border-radius: 100%;
        top: -15px;

        &--left {
            left: -40px;
        }
        &--right {
            right: -40px;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1.1);
        }
    }

    &__btn {
        position: fixed;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;

        span {
            font-size: 14px;
            color: $color_light-blue;
        }

        &:hover {
            opacity: 1;
        }

        &--top {
            top: 0;
            right: 0;
        }

        &--bottom {
            bottom: 0;
            right: 0;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-secret-enter-active,
    .fade-secret-leave-active {
        transition: opacity 1.8s ease;
    }

    .fade-secret-enter-from,
    .fade-secret-leave-to {
        opacity: 0;
    }
}
</style>
