<template>
    <section class="rest-api">
        <my-loader v-if="!isLoading" />

        <h1 class="rest-api__title">Взаимодействия с&nbsp;<a href="https://ru.wikipedia.org/wiki/REST" target="blank">Api</a></h1>

        <p class="rest-api__text">На этой страницы я буду взаимодействовать с различными <a href="https://ru.wikipedia.org/wiki/REST" target="blank">Api</a> взятые в свободном доступе Интернета, некоторые имеют ограничения на запросы, что-то можно получить только через <a href="https://ru.wikipedia.org/wiki/JSON_Web_Token" target="blank">токен</a>, тематика будет будет зависеть от предоставления данных различными ресурсами. Постепенно раздел будет расширяться.</p>

        <h2 class="rest-api__subtitle">Лига чемпионов УЕФА 2024-2025</h2>

        <div class="rest-api__tabs">
            <div class="rest-api__tab"
                v-if="arrFutureMatches.length > 0 && arrPostMatches.length > 0"
                v-for="(tab, index) in tabsSoccer" :key="index"
                :class="{'active' : tab.isActive}" @click="hundlerTabSoccer(tab)"
            >{{ tab.name }}</div>
        </div>

        <ul v-if="arrFutureMatches && activeTabContent == 'schedule'" class="rest-api__list">
            <li class="rest-api__item" v-for="(match, index) in arrFutureMatches[currentPage - 1]" :key="`future-match-${index}`">
                <span class="rest-api__date">{{ new Date(Date.parse(match.start_at) + 180 * 60 * 1000 ).toLocaleString("ru", {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric',
                }) }} мск</span>

                <div class="rest-api__team">
                    <img class="rest-api__team-logo" :src="match.home_team.logo" :alt="match.home_team.name_translations.ru" />

                    <span class="rest-api__team-name">{{ match.home_team.name_translations.ru }}</span>
                </div>

                <div v-if="!match.home_score && !match.away_score">
                    <span class="rest-api__team-goals">&nbsp;&ndash;&nbsp;</span>
                </div>

                <div class="rest-api__team">
                    <img class="rest-api__team-logo" :src="match.away_team.logo" :alt="match.away_team.name_translations.ru" />

                    <span class="rest-api__team-name">{{ match.away_team.name_translations.ru }}</span>
                </div>
            </li>
        </ul>

        <Pagination
            v-if="arrFutureMatches && activeTabContent == 'schedule'"
            :totalPages="arrFutureMatches.length"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
        />

        <ul v-if="arrPostMatches && activeTabContent == 'results'" class="rest-api__list">
            <li class="rest-api__item" v-for="(match, index) in arrPostMatches[currentPage - 1]" :key="`post-match-${index}`">
                <span class="rest-api__date">{{ new Date(Date.parse(match.start_at) + 180 * 60 * 1000 ).toLocaleString("ru", {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timezone: 'UTC',
                    hour: 'numeric',
                    minute: 'numeric',
                }) }} мск</span>

                <div class="rest-api__team">
                    <img class="rest-api__team-logo" :src="match.home_team.logo" :alt="match.home_team.name_translations.ru" />

                    <span class="rest-api__team-name">{{ match.home_team.name_translations.ru }}</span>
                </div>

                <div v-if="match.home_score && match.away_score">
                    <span class="rest-api__team-goals">&nbsp;{{ match.home_score.normal_time }}&nbsp;&ndash;&nbsp;{{ match.away_score.normal_time }}&nbsp;</span>
                </div>

                <div v-if="match.home_score && match.away_score && match.home_score.penalties && match.away_score.penalties">
                    <span>, пен:</span>

                    <span class="rest-api__team-goals">({{ match.home_score.penalties }}&nbsp;&ndash;&nbsp;{{ match.away_score.penalties }})&nbsp;</span>
                </div>

                <div class="rest-api__team">
                    <img class="rest-api__team-logo" :src="match.away_team.logo" :alt="match.away_team.name_translations.ru" />

                    <span class="rest-api__team-name">{{ match.away_team.name_translations.ru }}</span>
                </div>
            </li>
        </ul>

        <Pagination
            v-if="arrPostMatches && activeTabContent == 'results'"
            :totalPages="arrPostMatches.length"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
        />
    </section>
</template>

<script setup>
import { ref } from 'vue';
import axios from "axios";
import { useMeta } from 'vue-meta';
import { updateWidth } from "@/plugins/windowWidth.js";
import Pagination from "@/components/Pagination.vue";

const { windowWidth } = updateWidth();
const SIZE_ARR = 5;

const russianCup = ref([]);
const futureMatches = ref([]);
const arrFutureMatches = ref([]);
const postMatches = ref([]);
const arrPostMatches = ref([]);
const isLoading = ref(true);
const tabsSoccer = ref([
    {
        name: 'Расписание',
        id: "schedule",
        isActive: true
    },
    {
        name: 'Результаты',
        id: "results",
        isActive: false
    }
]);
const activeTabContent = ref(tabsSoccer.value[0].id);
const currentPage = ref(1);

const onPageChange = page => currentPage.value = page;

const hundlerTabSoccer = tab => {
    tabsSoccer.value.map(x => x.isActive = false);
    tab.isActive = true;
    activeTabContent.value = tab.id;
    currentPage.value = 1;
};

const fetchRussianCup = async () => {
    try {
        isLoading.value = false;
        const responce = await axios.get('https://sportscore1.p.rapidapi.com/seasons/43883/events', {
        headers: {
            'X-RapidAPI-Key': '0ba7fbb540msh72d9f6e55e40b33p1c682ejsne8c484eb694a',
            'X-RapidAPI-Host': 'sportscore1.p.rapidapi.com'
            }
        });
        russianCup.value = responce.data.data;
        russianCup.value.map(x => x.status == "notstarted" ? futureMatches.value.push(x) : postMatches.value.push(x));
        futureMatches.value = futureMatches.value;
        futureMatches.value = futureMatches.value.reverse();
        arrFutureMatches.value = arrBreakdown(futureMatches.value, SIZE_ARR);
        arrPostMatches.value = arrBreakdown(postMatches.value, SIZE_ARR);
    } catch (error) {
        alert('Ошибка загрузки Sports')
        console.log(error);
    } finally {
        isLoading.value = true;
    }
};
fetchRussianCup();

const arrBreakdown = (src, count) => {
    const result = [];
    for (let s = 0, e = count; s < src.length; s += count, e += count) {
        result.push(src.slice(s, e));
    }
    return result;
};

useMeta({
    title: 'Разработка приложений на vue 3 | Взаимодействия с Api | Spartfin | Алексей Горынь | Портфолио разработчика',
    link: [{ rel: "canonical", href: `https://spartfin.ru/rest-api` }],
    description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Взаимодействия с Api | Spartfin | Алексей Горынь | Портфолио разработчика'
});
</script>

<style lang="scss" scoped>
.rest-api {
    &__title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;

        @include mq(1023) {
            font-size: 32px;
            margin: 0 100px 28px;
        }

        @include mq(767) {
            font-size: 26px;
            line-height: 1.12;
            margin: 0 32px 20px;
        }

        a {
            font-weight: 700;
            color: $color_blue;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__text {
        line-height: 1.5;
        margin-bottom: 24px;

        @include mq(1023) {
            line-height: 1.4;
            margin-bottom: 20px;
        }

        @include mq(767) {
            line-height: 1.3;
            margin-bottom: 16px;
        }

        a {
            font-weight: 700;
            color: $color_blue;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__subtitle {
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        margin: 0 0 24px;

        @include mq(1023) {
            font-size: 24px;
            margin: 0 0 20px;
        }

        @include mq(767) {
            font-size: 20px;
            line-height: 1.12;
            margin: 0 0 16px;
        }
    }

    &__tabs {
        display: flex;
        justify-content: center;
        margin: 0 0 32px;
    }

    &__tab {
        display: flex;
        align-items: center;
        width: max-content;
        padding: 12px 20px;
        border-radius: 24px;
        background-color: $color_light-blue;
        transition: 0.3s;
        margin: 0 12px 0 0;
        cursor: pointer;

        &:last-child {
            margin: 0;
        }

        @include mq(1023) {
            transition: none;
        }

        &:hover {
            color: $color_red;
            background-color: $color_light-blue;
            box-shadow: 8px 8px 10px $box_shadow;
            transform: scale(1.05);

            .navbar__icon svg path {
                width: 24px;
                height: 24px;
                stroke: $color_blue;
            }
        }

        &.active {
            color: $color_blue;
            background-color: $color_light-blue;
            box-shadow: 8px 8px 10px $box_shadow;
            transform: scale(1);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 0 0 36px;

        @include mq(1339) {
            width: 100%;
        }
    }

    &__date {
        width: 100%;
        text-align: center;
        margin: 0 0 8px;
    }

    &__team {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33.333%;

        @include mq(767) {
            width: 100%;
        }
    }

    &__team-logo {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 4px 0 0;
    }

    &__team-goals {
        font-weight: 700;
        color: $color_blue;
    }
}
</style>
