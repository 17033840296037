<template>
    <section class="about">
        <my-loader v-if="!isLoading" />

        <h1 class="about__title">Давайте знакомиться</h1>

        <div class="about__info">
            <div class="about__info-avatar">
                <img src="img/avatar.webp" alt="Аватарка Алексея" />
            </div>

            <div class="about__info-content">
                <p class="about__text">Привет! Я <a href="https://vk.com/spartfin" target="blank">Алексей</a>, целеустремленный <a href="https://ru.wikipedia.org/wiki/Фронтенд" target="blank">Frontend-разработчик</a> со страстью к продуманному дизайну пользовательского интерфейса, совместной работе и обучению.</p>

                <p class="about__text">Как веб-разработчик, мне нравится использовать свое навязчивое внимание к деталям, мою безоговорочную любовь к созданию вещей и мою целеустремленную рабочую этику, чтобы буквально изменить мир.</p>
            </div>
        </div>

        <div class="about__career">
            <div class="about__career-studies">
                <p class="about__text">Свою карьеру разработчика я начинал в великолепной онлайн школе <a href="https://htmlacademy.ru" target="blank">HtmlAcademy</a>,
                прошёл полный курс по профессии <a href="https://htmlacademy.ru/profession/frontender" target="blank">«Фронтенд-разработчик»</a> и успешно защитил все учебные проекты.</p>

                <ul class="about__certificates-list">
                    <li class="about__certificates-item" v-for="(item, index) in data.certificatesList" :key="index" @click="showCertificate(item.src), isMobileOverflow()">
                        <img :src="item.src" alt="Сертификат" />
                    </li>
                </ul>

                <p class="about__text">Первым опытом коммерческой разработки был в&nbsp;команде сильнейших разработчиков, дизайнеров и мэнеджеров компании <a href="https://affinage.ru" target="blank">Affinage</a>.
                Почти за 2&nbsp;года в&nbsp;компании я прокачал свой уровень, удалось отточить многие навыки как в&nbsp;средних проектах, так и в&nbsp;довольно крупных, там я получил колоссальный опыт работы с командой.</p>

                <p class="about__text">Работал над проектами для <a href="https://softline.ru" target="blank">Softline</a>, есть опыт разработки сайтов для интерактивных панелей, к примеру мою работу можно увидеть в Кораблинском краеведческом музее.</p>

                <p class="about__text">Я постоянно учусь, совершенствуюсь в&nbsp;профессии, открываю для себя новые направления развития и горизонты <a href="https://ru.wikipedia.org/wiki/Веб" target="blank">Web</a> разработки.</p>
            </div>

            <div class="about__career-wrap">
                <ul class="about__career-list">
                    <li class="about__career-item" v-for="(item, index) in data.careerList" :key="index">
                        <span v-html="item.name"></span>

                        <span v-if="index != 4" v-html="item.text"></span>
                        <span v-if="index == 4" class="about__timer">{{ yearsAndMonthsBetweenDates('2020.06.01', new Date()).years }} {{ declension(yearsAndMonthsBetweenDates('2020.06.01', new Date()).years, years) }} {{ yearsAndMonthsBetweenDates('2020.06.01', new Date()).months }} {{ declension(yearsAndMonthsBetweenDates('2020.06.01', new Date()).months, months) }}</span>
                    </li>
                </ul>

                <div class="about__stack">
                    <span class="about__stack-text">Основной стек</span>

                    <a href="https://nuxtjs.org" target="blank">
                        <img class="about__stack-img" src="img/nuxt-logo.webp" alt="Логотип Nuxt" />

                        <span class="about__stack-text">Nuxt.js</span>
                    </a>
                </div>
            </div>
        </div>

        <my-popup class="about__popup" :show="isShow" @show="isShowUpdate">
            <img :src="srcCertificate" alt="Сертификат" @click="isShow=false, isMobileOverflow()" />
        </my-popup>
    </section>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from "axios";
import { useMeta } from 'vue-meta';

const isLoading = ref(true);
const isShow = ref(false);
const srcCertificate = ref('');
const countdownInterval = ref(null);
const remainingTime = ref(null);
const months = ['яблоко', 'месяца', 'месяцев'];
const years = ['год', 'года', 'лет'];
const data = ref({
    careerList: []
});

onMounted(() => {
    startCountdown();
});

const startCountdown = () => {
    let date = "2020.06.01";

    countdownInterval.value = setInterval(() => {
        const difference = (new Date().getTime() - new Date(date));

        if (difference > 0) {
            remainingTime.value = calculateTime(difference);
        } else {
            remainingTime.value = null;
            clearInterval(countdownInterval.value);
        }
    }, 1000);
};

/* Смотрим год високосный или нет */
const getLeapYear = year => year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

/* Получаем текущий год */
const currentYear = new Date().getFullYear();

const calculateTime = (timeDifference) => {
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return { days, hours: hours < 10 ? '0'+ hours : hours, minutes: minutes < 10 ? '0'+ minutes : minutes, seconds: seconds < 10 ? '0'+ seconds : seconds };
};


/* Год и месяц между датами */
const yearsAndMonthsBetweenDates = (startDate, endDate) => {
    let start = new Date(startDate);
    let end = new Date(endDate);

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    if (months < 0) {
        years--;
        months += 12;
    }

    return { years, months };
}

const age = computed(() => {
    let date = "1984.08.16";

    return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000) | 0) + '&nbsp;лет';
});

const declension = (n, forms) => {
    return forms[(n % 10 === 1 && n % 100 !== 11) ? 0 : ((n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) ? 1 : 2)];
};

const fetch = async () => {
    try {
        isLoading.value = false;
        const responce = await axios.get('./json/app.json');
        data.value = responce.data.about;
        data.value.careerList[2].text = age;
        data.value.careerList[4].text = `более 4&nbsp;лет`;
    } catch (error) {
        alert('Ошибка загрузки data about')
    } finally {
        isLoading.value = true;
    }
};
fetch();

const isShowUpdate = date => {
    isShow.value = date;
    isMobileOverflow();
}

const showCertificate = src => {
    isShow.value = true;
    srcCertificate.value = src;
}

const isMobileOverflow = () => {
    if(isShow.value === true) {
        document.body.classList.add('is-mobile-overflow');
    } else {
        document.body.classList.remove('is-mobile-overflow');
    }
};

useMeta({
    title: 'Разработка приложений на vue 3 | Обо мне | О Алексее Горынь',
    link: [{ rel: "canonical", href: `https://spartfin.ru/about` }],
    description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика | О Алексее Горынь'
});
</script>

<style lang="scss" scoped>
.about {
    &__title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;

        @include mq(1023) {
            font-size: 32px;
            margin: 0 100px 28px;
        }

        @include mq(767) {
            font-size: 26px;
            line-height: 1.12;
            text-align: center;
            margin: 0 40px 20px;
        }
    }

    &__info {
        display: flex;
        width: 100%;
        margin: 0 0 16px;

        @include mq(767) {
            flex-direction: column;
            margin: 0;
        }
    }

    &__info-avatar {
        position: relative;
        width: 156px;
        min-width: 156px;
        height: 156px;
        min-height: 156px;

        @include mq(767) {
            width: 100%;
            min-width: auto;
            height: 280px;
            min-height: 280px;
            margin: 0 0 12px;
        }

        @include mq(411) {
            height: 220px;
            min-height: 220px;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -4px;
            bottom: -4px;
            left: -4px;
            right: -4px;
            background: linear-gradient(to left, #743ad5, #d53a9d);
            border-radius: 50%;

            @include mq(767) {
                content: none;
            }
        }

        img {
            border-radius: 50%;
            border: 3px solid $color_white;

            @include mq(767) {
                border: none;
                border-radius: 0;
                object-fit: cover;
            }
        }
    }

    &__info-content {
        margin: 0 0 0 32px;

        @include mq(767) {
            margin: 0;
        }
    }

    &__text {
        line-height: 1.5;
        margin-bottom: 8px;

        @include mq(1023) {
            line-height: 1.4;
        }

        @include mq(767) {
            line-height: 1.3;
        }

        span {
            font-weight: 700;
            color: $color_blue;
        }

        a {
            font-weight: 700;
            color: $color_blue;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__career {
        display: flex;
        width: 100%;

        @include mq(767) {
            flex-direction: column;
        }
    }

    &__certificates-list {
        display: flex;
        margin: 0 0 20px;

        @include mq(767) {
            width: 100%;
            margin: 0 0 16px;
            overflow-x: auto;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__certificates-item {
        margin: 0 4px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }

        @include mq(767) {
            min-width: 200px;
            margin: 0 8px 0 0;
            scroll-snap-align: center;

            &:last-child {
                margin: 0;
            }

            &:hover {
                transform: none;
            }

            img {
                display: block;
            }
        }
    }

    &__career-wrap {
        margin: 0 0 0 24px;

        @include mq(767) {
            margin: 16px 0 0;
        }
    }

    &__career-list {
        position: relative;
        padding: 8px;
        display: flex;
        flex-direction: column;
        width: max-content;
        height: max-content;
        background-color: $color_gray;
        margin: 0 0 32px;

        @include mq(767) {
            width: 100%;
            margin: 0 0 12px;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -5px;
            bottom: -5px;
            left: -5px;
            right: -5px;
            background: linear-gradient(to left, #00C853, #B2FF59);
            border-radius: 5px;
        }
    }

    &__career-item {
        display: flex;
        align-items: center;
        margin: 2px;

        span {
            &:first-child {
                min-width: 90px;
                margin: 0 12px 0 0;
            }
        }
    }

    &__stack-text {
        display: block;
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        color: $color_green;
        text-align: center;
        text-shadow: 0 4px 3px rgba(0, 0, 0, 0.4), 0 8px 13px rgba(0, 0, 0, 0.1), 0 18px 23px rgba(0, 0, 0, 0.1);
        margin: 0 0 8px;
    }

    &__stack-img {
        animation: animalVue 8s infinite;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }

    @keyframes animalVue {
        0% {
            transform: rotateY(45deg);
        }

        100% {
            transform: rotateY(-45deg);
        }
    }

    &__popup {
        img {
            display: block;
            height: 90vh;
            border-radius: 12px;
            cursor: pointer;

            @include mq(767) {
                height: auto;
                max-height: 90vh;
            }
        }
    }
}
</style>
