<template>
    <ul class="pagination">
        <li v-if="windowWidth > 767" class="pagination__item" :class="{ 'disabled' : isInFirstPage }">
            <span @click="$emit('pagechanged', 1)" :disabled="isInFirstPage">Первая</span>
        </li>

        <li class="pagination__item" :class="{ 'disabled' : isInFirstPage }">
            <span  @click="$emit('pagechanged', currentPage - 1)" :disabled="isInFirstPage">{{ windowWidth > 767 ? 'Предыдущая' : 'Назад'}}</span>
        </li>

        <!-- Visible Buttons Start -->
        <li class="pagination__item pagination__item--visible" v-for="page in pages" :key="page.isDisabled">
            <span :disabled="page.isDisabled" :class="{ 'active' : currentPage + 1 }">{{ currentPage }}</span>

            <span>&nbsp;из&nbsp;</span>

            <span>{{ totalPages }}</span>
        </li>
        <!-- Visible Buttons End -->

        <li class="pagination__item" :class="{ 'disabled' : isInLastPage }">
            <span @click="$emit('pagechanged', currentPage + 1)" :disabled="isInLastPage">{{ windowWidth > 767 ? 'Следующая' : 'Вперед'}}</span>
        </li>

        <li v-if="windowWidth > 767" class="pagination__item" :class="{ 'disabled' : isInLastPage }">
            <span @click="$emit('pagechanged', totalPages)" :disabled="isInLastPage">Последняя</span>
        </li>
    </ul>
</template>

<script setup>
import { ref, computed } from 'vue'
import { updateWidth } from "@/plugins/windowWidth.js";

const { windowWidth } = updateWidth();
const props = defineProps({
    maxVisibleButtons: {
        type: Number,
        required: false,
        default: 1
    },
    totalPages: {
        type: Number,
        required: true
    },
    currentPage: {
        type: Number,
        required: true
    }
});

const startPage = computed(() => {
    // When on the first page
    if (props.currentPage === 1) {
        return 1;
    }

    // When on the last page
    if (props.currentPage === props.totalPages) {
        return props.totalPages - props.maxVisibleButtons;
    }

    // When inbetween
    return props.currentPage - 1;
});

const pages = computed(() => {
    const range = [];

    for (let i = startPage.value; i <= Math.min(startPage.value + props.maxVisibleButtons - 1, props.totalPages); i++) {
        range.push({
            isDisabled: i === props.currentPage
        });
    }

    return range;
});

const isInFirstPage = computed(() => props.currentPage === 1);

const isInLastPage = computed(() => props.currentPage === props.totalPages);
</script>

<style lang="scss" scoped>
.pagination {
    display: flex;
    justify-content: space-around;
    margin-bottom: 24px;

    @include mq(1023) {
        justify-content: space-between;
        margin-bottom: 16px;
    }

    &__item {
        display: flex;
        align-items: center;
        width: max-content;
        padding: 12px 20px;
        border-radius: 24px;
        background-color: $color_light-blue;
        transition: 0.3s;
        margin: 0 12px 0 0;
        cursor: pointer;

        &:last-child {
            margin: 0;
        }

        @include mq(1023) {
            transition: none;
        }

        @include mq(787) {
            padding: 10px 16px;
            margin: 0 10px 0 0;
        }

        &:hover {
            color: $color_red;
            background-color: $color_light-blue;
            box-shadow: 8px 8px 10px $box_shadow;
            transform: scale(1.05);

            @include mq(787) {
                color: $color_black;
                box-shadow: none;
                transform: scale(1);
            }
        }

        &--visible {
            display: flex;
            align-items: center;
            pointer-events: none;
        }
    }

    .active {
        color: $color_red;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>
