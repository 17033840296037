<template>
    <section  class="skills">
        <my-loader v-if="!isLoading" />

        <h1 class="skills__title">Инструменты разработки и&nbsp;технологии</h1>

        <span class="skills__text">На этой странице вы найдёте список основных инструментов и технологий которые я использую для быстрой и эффективной разработки. О&nbsp;каждом инструменте и технологии вы можете узнать больше кликнув по нему.</span>

        <ul v-if="skills.skillList" class="skills__list">
            <li class="skills__item" v-for="(skill, index) in skills.skillList" :key="`skill-${index}`">
                <a class="skills__link" :href="skill.link" target="blank">
                    <img class="skills__icon" :src="skill.img.src" :alt="skill.img.alt" />

                    <span class="skills__text--skill" v-html="skill.text"></span>
                </a>
            </li>
        </ul>
    </section>
</template>

<script setup>
import { ref } from 'vue'
import axios from "axios";
import { useMeta } from 'vue-meta'
import { updateWidth } from "@/plugins/windowWidth.js";

const skills = ref({});
const isLoading = ref(true);
const { windowWidth } = updateWidth();

const fetchSkills = async () => {
    try {
        isLoading.value = false;
        const responce = await axios.get('./json/app.json');
        skills.value = responce.data.skills;
    } catch (error) {
        alert('Ошибка загрузки данных навыков')
    } finally {
        isLoading.value = true;
    }
};
fetchSkills();

useMeta({
    title: 'Разработка приложений на vue 3 | Навыки | Навыки Алексея Горынь | Spartfin | Алексей Горынь | Портфолио разработчика',
    link: [{ rel: "canonical", href: `https://spartfin.ru/skills` }],
    description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика | Навыки | Навыки Алексея Горынь | Скиллы | Инструменты разработки | Технологии фронтенд разработки | Скиллы Алексея Горынь'
});
</script>

<style lang="scss" scoped>
.skills {
    &__title {
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 32px;

        @include mq(1023) {
            font-size: 32px;
            margin: 0 100px 28px;
        }

        @include mq(767) {
            font-size: 26px;
            line-height: 1.12;
            margin: 0 32px 20px;
        }
    }

    &__text {
        line-height: 1.5;

        &--skill {
            text-align: center;
        }

        @include mq(1023) {
            line-height: 1.4;
        }

        @include mq(767) {
            line-height: 1.3;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 24px 0 0;

        @include mq(767) {
            margin: 12px 0 0;
        }
    }

    &__item {
        width: calc(20% - 24px);
        padding: 12px;
        background-color: #f0f0f3;
        box-shadow: 10px 10px 21px #d3d3d4, -10px -10px 21px $color_white;
        border-radius: 12px;
        margin: 12px;
        transition: all 0.3s;

        @include mq(1199) {
                width: calc(33.333% - 20px);
                padding: 16px;
                margin: 10px;
            }

        @include mq(767) {
            width: calc(50% - 12px);
            padding: 10px;
            margin: 6px;
        }

        &:hover {
            background: $color_light-blue;
            box-shadow:  10px 10px 21px #bec7d9, -10px -10px 21px $color_white;
            transform: scale(1.03);

            .contacts__text {
                color: $color_red;
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;

        img {
            display: block;
            width: 120px;
            height: 120px;
            margin: 6px auto;

            @include mq(767) {
            }
        }
    }
}
</style>
