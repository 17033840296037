<template>
    <div class="popup" v-if="show" @click="$emit('show', false)">
        <div @click.stop class="popup__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'my-popup',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);

    &__content {
        min-width: 300px;
        min-height: 50px;
        padding: 24px;
        border-radius: 12px;
        background-color: #fff;
        margin: auto;

        @include mq(767) {
            max-width: 90vw;
            padding: 12px;
        }
    }
}
</style>
