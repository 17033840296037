import { createApp } from 'vue'
import App from './App'
import componentsUI from "@/components/UI"
import router from '@/router/router';
import directives from "@/directives"
import './assets/scss/main.scss';
import { createMetaManager } from 'vue-meta'
import './registerServiceWorker'

const app = createApp(App)

componentsUI.forEach(component => {
    app.component(component.name, component);
});

directives.forEach(directive => {
    app.directive(directive.name, directive);
});

app
    .use(router)
    .use(createMetaManager())
    .mount('#app');
