<template>
    <section class="navbar" :class="{'is-open' : isOpen }">
        <div v-if="windowWidth < 1024" class="navbar__menu" :class="{'is-open' : isOpen }">
            <svg v-if="!isOpen" @click="isOpen = true, isMobileOverflow()" class="navbar__burger" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 10H3M21 6H3M21 14H3M21 18H3" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>

            <svg v-if="isOpen" @click="isOpen = false, isMobileOverflow()" class="navbar__burger" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6 6 18M6 6l12 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>

        <div class="navbar__content" :class="{'is-open' : isOpen }">
            <div class="navbar__logo" @click="$router.push('/')">
                <img src="img/icon-logo.png" @click="isOpen = false, isMobileOverflow()" alt="Логотип приложения Spartfin" />
            </div>

            <ul class="navbar__list">
                <li class="navbar__item" v-for="(link, index) in navBar.links" :key="index">
                    <router-link class="navbar__link" :to="link.href" @click="isOpen = false, isMobileOverflow()" :class="{'active' : link.href === $route.path}">
                        <div class="navbar__icon" v-html="link.icon"></div>

                        {{ link.title }}
                    </router-link>
                </li>
            </ul>

            <span
                class="navbar__marquee"
                :class="item.class"
                :style="{ backgroundColor: item.color }"
                v-for="(item, index) in navBar.marquee"
                :key="index"
                v-html="item.title"
            ></span>

            <span class="navbar__small-text">{{ currentYear }} &copy; Spartfin</span>
        </div>
    </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import { updateWidth } from "@/plugins/windowWidth";

const { windowWidth } = updateWidth();
const isOpen = ref(false);

const props = defineProps({
    navBar: {
        type: Object,
        require: true
    },
});

const isMobileOverflow = () => {
    if(isOpen.value === true) {
        document.body.classList.add('is-mobile-overflow');
    } else {
        document.body.classList.remove('is-mobile-overflow');
    }
};

const currentYear = computed(() => new Date().getFullYear());
</script>

<style lang="scss">
.navbar {
    position: fixed;
    top: 0;
    left: 1%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    width: 272px;
    min-width: 272px;
    height: 100vh;

    @include mq(1023) {
        position: absolute;
        width: 0;
        min-width: auto;
        height: 0;

        &.is-open {
            position: fixed;
            width: 100%;
            height: 100vh;
        }
    }

    &__menu {
        position: fixed;
        left: -120px;
        top: -120px;
        z-index: 99;
        width: 250px;
        height: 250px;
        background-color: $color_dark-yellow;
        border-radius: 100%;
        cursor: pointer;
        transition: all 0.3s;

        @include mq(1023) {
            left: -130px;
            top: -130px;
            width: 240px;
            height: 240px;
        }

        @include mq(767) {
            left: -60px;
            top: -60px;
            width: 120px;
            height: 120px;
        }

        &.is-open {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            border-radius: 0;
            transition: all 0.3s;

            .navbar__burger {
                top: 12px;
                left: 12px;

                @include mq(767) {
                    top: 6px;
                    left: 6px;
                }
            }
        }
    }

    &__burger {
        position: absolute;
        top: 132px;
        left: 132px;
        z-index: 100;
        width: 72px;
        height: 72px;
        transition: all 0.3s;

        @include mq(767) {
            top: 64px;
            left: 66px;
            width: 36px;
            height: 36px;
        }

        &:hover {
            opacity: 1;
            transform: scale(1.2);
            transition: all 0.3s;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 24px 28px 0 24px;
        overflow-y: auto;

        /* Скрываем scrollbar для IE, Edge и Firefox */
        -ms-overflow-style: none;  /* IE и Edge */
        scrollbar-width: none;  /* Firefox */

        /* Скрываем scrollbar для Chrome, Safari и Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        @include mq(1023) {
            position: relative;
            z-index: 100;
            width: 272px;
            padding: 48px 24px;
            margin: 0 auto;
            opacity: 0;
            transform: translateX(-200%);
            transition: all 0.3s;

            &.is-open {
                opacity: 1;
                transform: translateX(0);
            }
        }

        @include mq(767) {
            width: 248px;
            padding: 32px 20px;
        }
    }

    &__logo {
        margin-bottom: 16px;
        cursor: pointer;
    }

    &__list {
        margin-bottom: 42px;
    }

    &__item {
        margin-bottom: 12px;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 20px;
        border-radius: 24px;
        transition: 0.3s;

        @include mq(1023) {
            transition: none;
        }

        &:hover {
            color: $color_red;
            background-color: $color_light-blue;
            box-shadow: 8px 8px 10px $box_shadow;
            transform: scale(1.05);

            .navbar__icon svg path {
                width: 24px;
                height: 24px;
                stroke: $color_blue;
            }
        }

        &.active {
            color: $color_blue;
            background-color: $color_light-blue;
            box-shadow: 8px 8px 10px $box_shadow;
            transform: scale(1);

            .navbar__icon svg path {
                width: 24px;
                height: 24px;
                stroke: $color_blue;
            }
        }
    }

    &__icon {
        display: flex;
        margin-right: 8px;
    }

    &__marquee {
        height: 38px;
        min-height: 38px;
        padding: 6px;
        white-space: nowrap; /* Запрещаем переносы слов */
        border-radius: 0.5em;
        box-shadow: 0 0.1em 0.5em;
        animation: marquee 8s linear infinite alternate;
        margin: 0 0 20px;
        overflow: hidden;

        &.html {
            animation: marquee 6s linear infinite alternate;
        }

        &.css {
            animation: marquee 7s linear infinite alternate;
        }

        &.vue {
            animation: marquee 9s linear infinite alternate;
        }

        @include mq(1023) {
            display: none;
        }
    }

    &__small-text {
        font-size: 12px;
        margin-bottom: 32px;

        @include mq(1023) {
            text-align: center;
            margin-bottom: 0;
        }
    }
}

@keyframes marquee {
    from {
        text-indent: 110%;
    }

    to {
        text-indent: -100%;
    }
}
</style>
